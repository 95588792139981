import type { DashboardConfig, SiteConfig } from "~/types";

export const siteConfig: SiteConfig = {
	name: "Mayicard",
	description:
		"An open source application built using the new router, server components and everything new in Next.js 13.",
	url: "https://assuarance.mayicard.com",
	ogImage: "https://tx.shadcn.com/og.jpg",
	links: {
		twitter: "https://twitter.com/MukasaMulya",
		github: "https://github.com/mayicard/mayiweb",
		linkedin: "https://www.linkedin.com/in/albertluganga/",
	},
};

export const dashboardConfig: DashboardConfig = {
	mainNav: [
		// {
		//     title: "Documentation",
		//     href: "/docs",
		// },
		// {
		//     title: "Support",
		//     href: "/support",
		//     disabled: true,
		// },
	],
	sidebarNav: [
		{
			title: "Posts",
			href: "/dashboard",
			icon: "post",
		},
		{
			title: "Billing",
			href: "/dashboard/billing",
			icon: "billing",
		},
		{
			title: "Settings",
			href: "/dashboard/settings",
			icon: "settings",
		},
	],
};
