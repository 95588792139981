import { Form, Link } from "@remix-run/react";
import { CgOrganisation } from "react-icons/cg";
import { FaNewspaper, FaSignOutAlt } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { Button } from "~/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { useSession } from "~/contexts/session";
import { FeatureFlags } from "~/utils/features";
import { Feature, ToggleDisplay } from "../controls";

function NavigationLink({ icon, label, route }: any) {
	return (
		<Link to={route} className="flex items-center gap-x-3.5">
			{icon}
			{label}
		</Link>
	);
}

export function LogoutButton() {
	return (
		<Form action="/logout" method="post">
			<button type="submit" className="flex items-center gap-x-3.5">
				<FaSignOutAlt />
				Logout
			</button>
		</Form>
	);
}

export function UserNav() {
	const { user, profile } = useSession();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative h-8 w-8 rounded-full">
					<Avatar className="h-8 w-8">
						<AvatarImage src="/avatars/01.png" alt="@shadcn" />
						<AvatarFallback>SC</AvatarFallback>
					</Avatar>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<div className="flex flex-col space-y-1">
						<p className="text-sm font-medium leading-none">{user.name}</p>
						<p className="text-xs leading-none text-muted-foreground">
							{user.email}
						</p>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<DropdownMenuItem>
						<NavigationLink
							icon={<FaNewspaper />}
							label="Profile"
							route="/profile"
						/>
					</DropdownMenuItem>
					<ToggleDisplay show={profile?.kind === "organisation"}>
						<Feature name={FeatureFlags.Superuser}>
							<DropdownMenuItem>
								<NavigationLink
									icon={<CgOrganisation />}
									label="Organisations"
									route="/organisations"
								/>
							</DropdownMenuItem>
						</Feature>
					</ToggleDisplay>
					<Feature>
						<DropdownMenuItem>
							<NavigationLink
								icon={<FaGear />}
								label="Settings"
								route="/settings"
							/>
						</DropdownMenuItem>
					</Feature>
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<DropdownMenuItem>
					<LogoutButton />
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
