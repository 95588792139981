"use client";

import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import * as React from "react";

import { Link, useLocation, useNavigate } from "@remix-run/react";
import { CgOrganisation } from "react-icons/cg";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { Button, buttonVariants } from "~/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "~/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "~/components/ui/popover";
import { useSession } from "~/contexts/session";
import { getQueryParams } from "~/data/api/utils";
import { cn } from "~/lib/utils";
import { AppRoutes } from "~/utils/routes";
import { ToggleDisplay } from "./controls";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
	typeof PopoverTrigger
>;

interface ProfileSwitcherProps extends PopoverTriggerProps {}

export default function ProfileSwitcher({ className }: ProfileSwitcherProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const {
		user: { profiles },
		profile: currentProfile,
	} = useSession();

	const [open, setOpen] = React.useState(false);
	const [selectedProfile, setSelectedProfile] =
		React.useState<any>(currentProfile);

	const canOpen = profiles?.length > 1 ? open : false;

	const handleProfileChange = (profile: any) => {
		setSelectedProfile(profile);
		setOpen(false);
		const params = {
			org_id: profile?.uuid,
			redirectTo: location.pathname,
		};

		navigate(`${AppRoutes.PROFILE_SELECT}?${getQueryParams(params)}`);
	};

	return (
		<>
			<Link
				to={AppRoutes.PROFILE_SELECT}
				className={cn(
					buttonVariants({ variant: "secondary", size: "sm" }),
					"px-4",
				)}
			>
				Switch Profile
			</Link>
			<ToggleDisplay>
				<Popover open={canOpen} onOpenChange={setOpen}>
					<PopoverTrigger asChild>
						<Button
							variant="outline"
							role="combobox"
							aria-expanded={open}
							aria-label="Select a profile"
							className={cn("w-[250px] justify-between", className)}
						>
							<Avatar className="mr-2 h-5 w-5">
								<AvatarImage
									// src={`https://avatar.vercel.sh/${selectedProfile.uuid}.png`} TODO: Add organisation images and show them here
									alt={selectedProfile.name}
									className="grayscale"
								/>
								<AvatarFallback>
									<CgOrganisation />
								</AvatarFallback>
							</Avatar>
							<span className="mr-auto truncate">{selectedProfile.name}</span>
							{profiles?.length > 1 ? (
								<CaretSortIcon className="h-4 w-4 shrink-0 opacity-50" />
							) : (
								<span />
							)}
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-[300px] p-0">
						<Command>
							<CommandList>
								<CommandInput
									className="focus:border-slate-200 focus:ring-slate-200 border-0"
									placeholder="Search profiles..."
								/>
								<CommandEmpty>No profile found.</CommandEmpty>
								<CommandGroup heading="Profiles">
									{profiles.map((profile: any) => (
										<CommandItem
											key={profile.uuid}
											onSelect={() => {
												handleProfileChange(profile);
											}}
											className="text-sm"
										>
											<Avatar className="mr-2 h-5 w-5">
												<AvatarImage alt={profile.name} className="grayscale" />
												<AvatarFallback>
													<CgOrganisation />
												</AvatarFallback>
											</Avatar>
											{profile.name}
											<CheckIcon
												className={cn(
													"ml-auto h-4 w-4",
													selectedProfile.uuid === profile.uuid
														? "opacity-100"
														: "opacity-0",
												)}
											/>
										</CommandItem>
									))}
								</CommandGroup>
							</CommandList>
							<CommandSeparator />
						</Command>
					</PopoverContent>
				</Popover>
			</ToggleDisplay>
		</>
	);
}
