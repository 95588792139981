import { Link, useLocation } from "@remix-run/react";
import React from "react";
import { siteConfig } from "~/config/site";
import { cn } from "~/lib/utils";
import type { MainNavItem } from "~/types";
import { FeatureFlags } from "~/utils/features";
import { AppRoutes } from "~/utils/routes";
import { Feature } from "../controls";
import { Icons } from "../icons";
import ProfileSwitcher from "../profile-switcher";
import { MobileNav } from "./mobile-nav";

interface MainNavProps {
	items?: MainNavItem[];
	children?: React.ReactNode;
}

export function MainNav({ items, children }: MainNavProps) {
	const segment = useLocation().pathname.split("/")[1];
	const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

	return (
		<>
			<div className="flex gap-6 md:gap-10">
				<Link
					to={AppRoutes.INDEX}
					className="hidden items-center space-x-2 md:flex"
				>
					<Icons.logo />
					<span className="hidden font-bold sm:inline-block">
						{siteConfig.name}
					</span>
				</Link>
				{items?.length ? (
					<nav className="hidden gap-6 md:flex">
						{items?.map((item, index) => (
							<Link
								key={index}
								to={item.disabled ? "#" : item.href}
								className={cn(
									"flex items-center text-lg font-medium transition-colors hover:text-foreground/80 sm:text-sm",
									item.href.startsWith(`/${segment}`)
										? "text-foreground"
										: "text-foreground/60",
									item.disabled && "cursor-not-allowed opacity-80",
									item.hide && "hidden",
								)}
							>
								{item.title}
							</Link>
						))}
					</nav>
				) : null}
				<button
					className="flex items-center space-x-2 md:hidden"
					onClick={() => setShowMobileMenu(!showMobileMenu)}
				>
					{showMobileMenu ? <Icons.close /> : <Icons.logo />}
					<span className="font-bold">Menu</span>
				</button>
				{showMobileMenu && items && (
					<MobileNav items={items}>
						{children}
						<Feature name={FeatureFlags.HasProfiles}>
							<ProfileSwitcher />
						</Feature>
					</MobileNav>
				)}
			</div>
		</>
	);
}
